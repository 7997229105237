import { isUserAuthorized } from 'utils/helpers';
import { getPromotionsInfo } from 'utils/business-details';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { PromotionsCells } from 'constants/business-details';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';

const PromotionsInfo = ({ promotionsData }) => {
  const promotionsInfo = getPromotionsInfo(promotionsData);

  const showPromotions =
    !!promotionsData?.length &&
    isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
      ACL_MATRIX.PROMOTIONS_LIST
    ]);

  if (!showPromotions) {
    return null;
  }

  return (
    <BRInformationTable
      dataSource={promotionsInfo}
      cells={PromotionsCells()}
      totalCol={3}
    />
  );
};

export default PromotionsInfo;
