/* eslint-disable max-len */
import React from 'react';

import { COUNTRIES } from 'constants/country-data';
import { ACL_MATRIX, PAGES_ACCESS } from 'common/permissions';
import { isCashierPageAvailable } from 'common/countries/countries-mapping';
import {
  ALL_COUNTRIES_CODES,
  COUNTRIES_CODE_NAME
} from 'common/countries/constants/countries';

import OrdersMassUpload from 'components/Shipments/components/MassUpload/MassUpload';
import CreateEditOrder from 'components/Shipments/CreateEditOrder/CreateEditOrder';
import OrderSummary from 'components/Shipments/CreateEditOrder/OrderSummary/OrderSummary';
import CodTransactions from './containers/CodTransactions/index';
import CodTransactionsDetails from './containers/CodTransactions/Details';
import Deliveries from './containers/Deliveries/index';
import NewDeliveryDetails from 'components/Shipments/components/NewDeliveryDetails/NewDeliveryDetails';
import ShipmentDetails from './containers/Deliveries/ShipmentDetails/ShipmentDetails';
import NewCreateEditPickupForm from './components/Pickups/components/CreateEditPickupForm/CreateEditPickupForm';
import Settings from 'components/NewSettings/Settings';
import AmazonRemittanceDetails from 'containers/CodTransactions/Details/AmazonRemittanceDetails/AmazonRemittanceDetails';
import aclMatrix, { EXPERIENCE } from './common/aclMatrix';
import PricingPlan from 'components/PricingPlan/PricingPlan';
import InternationalShipping from 'components/InternationalShipping/InternationalShipping';
import InternationalOrderDetails from 'components/InternationalShipping/components/InternationalOrderDetails/InternationalOrderDetails';
import InternationalItemDetails from 'components/InternationalShipping/components/InternationalItemDetails/InternationalItemDetails';
import HubsRoutes from 'containers/Hub/HubsRoutes';
import Quality from 'components/Quality/Quality';
import StarsRoutes from 'components/Stars/StarsRoutes';
import BusinessConfiguration from './components/Wallet/components/BusinessConfiguration/BusinessConfiguration';
import Inventory from 'components/Fulfillment/FulfillmentContainer';
import Cashier from 'components/Cashier/Cashier';
import HubPerformance from 'components/BibAnalytics/HubPerformance/HubPerformance';
import HubsPerformance from 'components/BibAnalytics/HubsPerformance/HubsPerformance';
import ReturnManifest from 'components/Routes/ReturnManifest/ReturnManifest';
import NewEditBusiness from 'components/Businesses/EditBusiness/EditBusiness';
import BarcodeScanning from 'components/HubOperations/BarcodeToAWB/components/BarcodeScanning/BarcodeScanning';
import Packaging from 'components/Packaging/Packaging';
import BulkPromotions from 'components/Promotions/components/BulkPromotions/BulkPromotions';
import RolesPermissions from 'components/RolesPermissions/RolesPermissions';
import InternationalOrdersContainer from 'components/InternationalOrders/InternationalOrdersContainer';
import BusinessesListing from 'components/Businesses/BusinessesListing/BusinessesListing';
import BulkUpdatePricingTier from 'components/BulkUpdatePricingTier/BulkUpdatePricingTier';
import RoutesListing from 'components/Routes/RoutesListing/RoutesListing';
import PickupsRequests from 'components/Pickups/PickupsRequests';
import PickupDetails from 'components/Pickups/components/PickupDetails/PickupDetails';
import InternationalDeliveriesE2E from 'components/InternationalOrders/components/InternationalDeliveriesE2E/InternationalDeliveriesE2E';
import FulfillmentRoutes from 'components/NewFulfillment/FulfillmentRoutes/FulfillmentRoutes';
import CapitalRoutes from 'components/BostaCapital/CapitalRoutes';
import OpsControlContainer from 'components/OpsControl/OpsControlContainer';
import NewBusinessDetails from 'components/NewBusinessDetails/NewBusinessDetails';

const CodGenerateReport = React.lazy(() =>
  import('./containers/CodTransactions/GenerateReport')
);

const Esclation = React.lazy(() => import('components/Escalation/Escalation'));
const SllrAnalyticsContainer = React.lazy(() =>
  import('components/SllrAnalytics/SllrAnalyticsContainer')
);
const DynamicRoutingContainer = React.lazy(() =>
  import('components/DynamicRouting/DynamicRoutingContainer')
);
const RouteRunSheet = React.lazy(() =>
  import('./containers/Route/RouteRunSheet/RouteRunSheet')
);
const RouteForm = React.lazy(() =>
  import('./containers/Route/RouteForm/RouteForm')
);

const HubManagement = React.lazy(() =>
  import('./containers/HubManagment/hubManagment')
);
const CreateHub = React.lazy(() =>
  import('./components/HubManagment/CreateEditHub/CreateEditHub.js')
);
const SupportTickets = React.lazy(() =>
  import('components/SupportTickets/SupportTickets')
);

const SubscriptionRoutes = React.lazy(() =>
  import('components/Subscription/SubscriptionRoutes')
);

const LiveOps = React.lazy(() => import('components/LiveOps/LiveOps'));
const Wallet = React.lazy(() => import('./components/Wallet/Wallet'));
const InternationalWallet = React.lazy(() =>
  import('components/WalletInternational/InternationalWallet')
);

const Pricing = React.lazy(() => import('./containers/Pricing/Pricing'));

export const AuditActions = React.lazy(() =>
  import('components/AuditActions/AuditActions')
);

const FulfillmentPricing = React.lazy(() =>
  import('components/FulfillmentPricing/FulfillmentPricing')
);

const InternationalPricing = React.lazy(() =>
  import('./components/internationalPricing/InternationalPricing')
);

const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country?.code;

const routes = [
  {
    path: '/businesses',
    module: 'BUSINESS',
    exact: true,
    name: 'Businesses',
    component: BusinessesListing,
    private: true,
    mainPage: true,
    access: [...aclMatrix.BUSINESSES],
    permission: PAGES_ACCESS.BUSINESSES_LIST,
    countries: ALL_COUNTRIES_CODES
  },
  {
    path: '/businesses/:id/details',
    module: 'BUSINESS',
    exact: true,
    name: 'Business Details',
    component: NewBusinessDetails,
    private: true,
    access: [...aclMatrix.BUSINESSES_DETAILS],
    permission: PAGES_ACCESS.BUSINESS_DETAILS,
    countries: ALL_COUNTRIES_CODES
  },
  {
    path: '/businesses/bulk',
    module: 'BUSINESS',
    exact: true,
    name: 'Upload Business Configuration',
    component: BusinessConfiguration,
    private: true,
    access: [...aclMatrix.BUSINESSES_DETAILS]
  },
  {
    path: '/businesses/bulk/promotions',
    module: 'BUSINESS',
    exact: true,
    name: 'Upload Business Promotion',
    component: BulkPromotions,
    private: true,
    access: [...aclMatrix.BUSINESSES_DETAILS]
  },
  {
    path: '/stars',
    name: 'Stars',
    component: StarsRoutes,
    private: true,
    mainPage: true,
    access: [...aclMatrix.STARS],
    permission: PAGES_ACCESS.STARS,
    countries: [
      COUNTRIES_CODE_NAME.EG,
      COUNTRIES_CODE_NAME.SA,
      COUNTRIES_CODE_NAME.QA
    ]
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: SubscriptionRoutes,
    private: true,
    mainPage: true,
    access: [...aclMatrix.STARS],
    permission: PAGES_ACCESS.STARS,
    countries: [COUNTRIES_CODE_NAME.EG, COUNTRIES_CODE_NAME.SA]
  },
  {
    path: '/businesses/:id/edit',
    module: 'BUSINESS',
    exact: true,
    name: 'Edit Business',
    component: NewEditBusiness,
    access: [...aclMatrix.BUSINESSES_EDIT]
  },
  {
    path: '/wallet',
    // exact: true,
    name: 'Wallet',
    component: Wallet,
    mainPage: true,
    access: [
      ...aclMatrix.WALLET,
      ...aclMatrix.FLEET_TEAM,
      ...aclMatrix.EXPERIENCE_TEAM
    ],
    permission: PAGES_ACCESS.WALLET
  },
  {
    path: '/wallet-international',
    // exact: true,
    name: 'International Wallet',
    component: InternationalWallet,

    permission: PAGES_ACCESS.INTERNATIONAL_WALLET,
    countries: [COUNTRIES_CODE_NAME.AE, COUNTRIES_CODE_NAME.EG]
  },
  {
    path: '/escalation',
    name: 'Escalation',
    component: Esclation,
    private: true,
    mainPage: true,
    access: [...aclMatrix.COD_TRANSACTIONS],
    countries: [COUNTRIES_CODE_NAME.EG],
    permission: [ACL_MATRIX.VIEW_ESCALATED_TICKETS]
  },
  {
    path: '/cod-reports',
    exact: true,
    name: 'COD Reports',
    component: CodTransactions,
    private: true,
    mainPage: true,
    access: [...aclMatrix.COD_TRANSACTIONS],
    permission: [ACL_MATRIX.FINANCE_REPORTS_VIEW_AMAZON_REMITTANCE],
    countries: [COUNTRIES_CODE_NAME.EG]
  },
  {
    path: '/cod-reports/generate-report',
    exact: true,
    name: 'COD Generate Reports',
    component: CodGenerateReport,
    private: true,
    access: [...aclMatrix.COD_TRANSACTIONS],
    permission: [ACL_MATRIX.FINANCE_REPORTS_VIEW_AMAZON_REMITTANCE]
  },
  {
    path: '/cod-reports/:id/details',
    exact: true,
    name: 'COD Reports',
    component: CodTransactionsDetails,
    private: true,
    access: [...aclMatrix.COD_TRANSACTIONS],
    permission: [ACL_MATRIX.FINANCE_REPORTS_VIEW_AMAZON_REMITTANCE]
  },
  {
    path: '/cod-reports/:id/amazon-details',
    exact: true,
    name: 'COD Reports',
    component: AmazonRemittanceDetails,
    private: true,
    access: [...aclMatrix.COD_TRANSACTIONS],
    permission: [ACL_MATRIX.FINANCE_REPORTS_VIEW_AMAZON_REMITTANCE]
  },
  {
    path: '/ops-control',
    name: 'OpsControl',
    component: OpsControlContainer,
    private: true,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: PAGES_ACCESS.DELIVERIES_LIST
  },
  {
    path: '/deliveries',
    exact: true,
    name: 'ListDeliveries',
    component: Deliveries,
    private: true,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: PAGES_ACCESS.DELIVERIES_LIST
  },
  {
    path: '/international-deliveries',
    exact: true,
    component: InternationalDeliveriesE2E,
    private: true,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: [ACL_MATRIX.INTERNATIONAL_ORDER_UNRESTRICTED_COUNTRIES_ACCESS]
  },
  {
    path: '/deliveries/Upload',
    exact: true,
    name: 'Deliveries',
    component: OrdersMassUpload,
    private: true,
    access: [...aclMatrix.DELIVERIES],
    permission: PAGES_ACCESS.DELIVERIES_CREATE
  },
  {
    path: '/deliveries/create',
    exact: true,
    name: 'NewOrder',
    component: CreateEditOrder,
    access: [...aclMatrix.DELIVERIES_CREATE],
    permission: PAGES_ACCESS.DELIVERIES_CREATE
  },
  {
    path: '/deliveries/create/order-summary',
    exact: true,
    name: 'OrderSummary',
    component: OrderSummary,
    private: true,
    access: [...aclMatrix.DELIVERIES_CREATE]
  },
  {
    path: '/deliveries/:id/details',
    exact: true,
    name: 'DeliveryDetails',
    component: NewDeliveryDetails,
    access: [
      ...aclMatrix.DELIVERIES_DETAILS,
      'WAREHOUSE_CLERK',
      'HUB_LEADER',
      'FLEET_MANAGER'
    ],
    permission: PAGES_ACCESS.DELIVERY_DEATILS
  },
  {
    path: '/deliveries/:id/edit',
    exact: true,
    name: 'EditOrder',
    component: CreateEditOrder,
    access: [...aclMatrix.DELIVERIES_EDIT]
  },
  {
    path: '/deliveries/:id/business-view-details',
    // exact: true,
    name: 'Deliveries',
    component: ShipmentDetails,
    private: true,
    access: [...aclMatrix.DELIVERIES_DETAILS]
  },
  {
    path: '/quality',
    exact: true,
    name: 'Quality',
    component: Quality,
    private: true,
    mainPage: true,
    access: [...aclMatrix.QUALITY],
    permission: [ACL_MATRIX.DELIVERY_VIEW_QUALITY_PAGE]
  },
  {
    path: '/box',
    exact: true,
    name: 'ListInternationalOrder',
    component: InternationalShipping,
    private: true,
    access:
      userCountry === COUNTRIES[0].codeName ? [...aclMatrix.BOSTA_BOX] : [],
    permission:
      userCountry === COUNTRIES[0].codeName ? [ACL_MATRIX.BOX_ITEM_LIST] : []
  },
  {
    path: '/box/order/:id',
    exact: true,
    name: 'InternationalOrderDetails',
    component: InternationalOrderDetails,
    private: true,
    access: [...aclMatrix.BOSTA_BOX]
  },
  {
    path: '/box/item/:id',
    exact: true,
    name: 'InternationalItemDetails',
    component: InternationalItemDetails,
    private: true,
    access: [...aclMatrix.BOSTA_BOX]
  },
  {
    path: '/routes',
    exact: true,
    name: 'Routes',
    component: RoutesListing,
    private: true,
    mainPage: true,
    access: [...aclMatrix.ROUTES],
    permission: PAGES_ACCESS.ROUTES
  },
  {
    path: '/routes/dynamic-routing',
    name: 'Dynamic Routing',
    component: DynamicRoutingContainer,
    private: true,
    access: [...aclMatrix.GEOFENCES],
    permission: PAGES_ACCESS.GEOFENCES
  },
  {
    path: '/routes/create',
    exact: true,
    name: 'Routes',
    component: RouteForm,
    private: true,
    access: [...aclMatrix.ROUTES_CREATE_EDIT],
    permission: PAGES_ACCESS.ROUTE_CREATE_EDIT
  },
  {
    path: '/routes/:id/edit',
    exact: true,
    name: 'Routes',
    component: RouteForm,
    private: true,
    access: [...aclMatrix.ROUTES_CREATE_EDIT],
    permission: PAGES_ACCESS.ROUTE_CREATE_EDIT
  },
  {
    path: '/routes/:routeId/return-manifest-details/:returnManifestId',
    name: 'Return Manifest',
    component: ReturnManifest,
    private: true,
    access: [...aclMatrix.DELIVERIES_DETAILS]
  },
  {
    path: '/pickups/create',
    exact: true,
    name: 'Pickups create',
    component: NewCreateEditPickupForm,
    private: true,
    access: [...aclMatrix.PICKUPS],
    permission: PAGES_ACCESS.PICKUPS_CREATE,
    countries: [
      COUNTRIES_CODE_NAME.EG,
      COUNTRIES_CODE_NAME.SA,
      COUNTRIES_CODE_NAME.QA
    ]
  },
  {
    path: '/international-pickups/create',
    exact: true,
    name: 'International Pickups Create',
    component: NewCreateEditPickupForm,
    initialProps: {
      isInternationalPickup: true
    },
    private: true,
    access: [...aclMatrix.PICKUPS],
    permission: PAGES_ACCESS.PICKUPS_CREATE,
    countries: [COUNTRIES_CODE_NAME.AE]
  },
  {
    path: '/pickups/edit/:id',
    exact: true,
    name: 'Pickup Request Edit',
    component: NewCreateEditPickupForm,
    private: true,
    access: [...aclMatrix.PICKUPS]
  },
  {
    path: '/routes/:id/run-sheet',
    exact: true,
    name: 'Routes',
    component: RouteRunSheet,
    private: true,
    access: [...aclMatrix.ROUTES]
  },
  {
    path: '/pickups',
    exact: true,
    name: 'Pickup Requests',
    component: PickupsRequests,
    private: true,
    mainPage: true,
    access: [...aclMatrix.PICKUPS],
    permission: PAGES_ACCESS.PICKUPS,
    countries: [
      COUNTRIES_CODE_NAME.EG,
      COUNTRIES_CODE_NAME.SA,
      COUNTRIES_CODE_NAME.QA
    ]
  },
  {
    path: '/pickups/:id',
    exact: true,
    name: 'Pickup Request Details',
    component: PickupDetails,
    private: true,
    access: [...aclMatrix.PICKUPS_DETAILS],
    permission: PAGES_ACCESS.PICKUP_DETAILS
  },
  {
    path: '/hubs',
    name: 'Hub',
    component: HubsRoutes,
    mainPage: true,
    access: [...aclMatrix.HUBS],
    permission: [
      ...PAGES_ACCESS.HUBS_OPERATIONS,
      ...PAGES_ACCESS.HUBS_MANAGEMENT,
      ...PAGES_ACCESS.ACTION_CENTER_HUB_VIEW,
      ...PAGES_ACCESS.ACTION_CENTER_AUDIT_VIEW,
      ...PAGES_ACCESS.GEOFENCES,
      ...PAGES_ACCESS.HUBS_OPERATIONS_MONITORING,
      ACL_MATRIX.ZONES_UPDATE,
      ACL_MATRIX.THREE_PL_PROVIDERS_CREATE_ORDER,
      ACL_MATRIX.NOTIFICATION_LIST_STAR_NOTIFICATION
    ],
    countries: [
      COUNTRIES_CODE_NAME.EG,
      COUNTRIES_CODE_NAME.SA,
      COUNTRIES_CODE_NAME.QA
    ]
  },
  {
    path: '/hub-management',
    exact: true,
    name: 'Hub Management',
    component: HubManagement,
    private: true,
    access: [...aclMatrix.HUB_MANAGEMENT, EXPERIENCE]
  },
  {
    path: '/hub-management/create-hub',
    exact: true,
    name: 'Create Hub',
    component: CreateHub,
    private: true,
    access: [...aclMatrix.HUB_CREATE_EDIT]
  },
  {
    path: '/hub-management/edit-hub/:id',
    exact: true,
    name: 'Create Hub',
    component: CreateHub,
    private: true,
    access: [...aclMatrix.HUB_CREATE_EDIT]
  },
  {
    path: '/upload-csv',
    exact: true,
    name: 'Upload CSV',
    component: () => <div>upload-csv</div>,
    private: true,
    access: [...aclMatrix.UPLOAD_CSV],
    exclude: true
  },

  {
    path: '/live-ops',
    exact: true,
    name: 'Live Ops',
    component: LiveOps,
    private: true,
    mainPage: true,
    access: [...aclMatrix.LIVEOPS],
    permission: PAGES_ACCESS.LIVE_OPS
  },
  // {
  //   path: '/inventory',
  //   name: 'Inventory',
  //   component: Inventory,
  //   private: true,
  //   mainPage: true,
  //   access: [...aclMatrix.INVENTORY],
  //   permission: [ACL_MATRIX.FULFILLMENT_PRODUCTS_LIST]
  // },
  // price plan in setting
  {
    path: '/pricing',
    exact: true,
    name: 'Pricing',
    component: Pricing,
    private: true,
    mainPage: true,
    access: [...aclMatrix.PRICING],
    permission: [ACL_MATRIX.PRICING_TIERS_GET]
  },
  {
    path: '/pricing/fulfillment',
    exact: true,
    name: 'Pricing',
    component: FulfillmentPricing,
    private: true,
    mainPage: true,
    access: [...aclMatrix.PRICING],
    permission: [ACL_MATRIX.FULFILLMENT_PRICING_TIERS_GET]
  },
  {
    path: '/pricing/international',
    name: 'Pricing',
    component: InternationalPricing,
    private: true,
    mainPage: true,
    access: [...aclMatrix.PRICING],
    permission: [ACL_MATRIX.FULFILLMENT_PRICING_TIERS_GET], // TODO update permission ?
    countries: [COUNTRIES_CODE_NAME.AE, COUNTRIES_CODE_NAME.EG]
  },
  {
    path: '/pricing-plan',
    exact: true,
    name: 'Pricing Plan',
    component: PricingPlan,
    private: true,
    access: [...aclMatrix.DELIVERIES_DETAILS]
  },
  {
    path: '/international-shipping',
    component: InternationalOrdersContainer,
    private: true,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: PAGES_ACCESS.INTERNATIOANL_SHIPPING,
    countries: [COUNTRIES_CODE_NAME.AE, COUNTRIES_CODE_NAME.EG]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    private: true,
    mainPage: true,
    access: [...aclMatrix.SETTINGS]
  },
  {
    path: '/cashier',
    exact: true,
    name: 'Cashier',
    component: Cashier,
    private: true,
    mainPage: true,
    access: isCashierPageAvailable() ? [...aclMatrix.CASHIER] : [],
    permission: [ACL_MATRIX.CASHIER_LIST],
    countries: [COUNTRIES_CODE_NAME.SA]
  },
  {
    path: '/hub-performance',
    exact: true,
    name: 'Hub Performance',
    component: HubPerformance,
    private: true,
    access: [...aclMatrix.BIB_ANALYTICS],
    permission: [ACL_MATRIX.HUBS_SCORES_LIST]
  },
  {
    path: '/hubs-performance',
    exact: true,
    name: 'Hubs Performance',
    component: HubsPerformance,
    private: true,
    access: [...aclMatrix.BIB_ANALYTICS],
    permission: [ACL_MATRIX.HUBS_SCORES_LIST_SUMMARY]
  },
  {
    path: '/roles-permissions',
    name: 'Roles & Permissions',
    component: RolesPermissions,
    private: true,
    mainPage: true
    // access: [...aclMatrix.ROLES_PERMISSIONS]
  },
  {
    path: '/sllr',
    name: 'Sllr Performance',
    component: SllrAnalyticsContainer,
    private: true
  },
  {
    path: '/capital',
    name: 'Capital',
    component: CapitalRoutes,
    private: true,
    mainPage: true,
    access: userCountry === COUNTRIES[0].codeName ? [...aclMatrix.BORROW] : [],
    permission:
      userCountry === COUNTRIES[0].codeName
        ? [
            ACL_MATRIX.FUNDING_PROFILES_GET,
            ACL_MATRIX.CAPITAL_CONFIGURATIONS_GET
          ]
        : []
  },
  {
    path: '/support',
    name: 'Support Tickets',
    component: SupportTickets,
    private: true,
    permission: [ACL_MATRIX.TICKETS_LIST]
  },
  {
    path: '/barcode-scanning',
    name: 'Barcode to AWB',
    component: BarcodeScanning,
    private: true
  },
  {
    path: '/packaging',
    name: 'Packaging',
    component: Packaging,
    private: true,
    mainPage: true,
    access: [...aclMatrix.PACKAGING_ACCESS, ...aclMatrix.PACKAGING_ORDER_PAGE],
    permission: [ACL_MATRIX.FLYERS_GET_BALANCE, ACL_MATRIX.FLYERS_ORDERS_PAGE]
  },
  {
    path: '/fulfillment',
    name: 'Fulfillment',
    component: FulfillmentRoutes,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: [ACL_MATRIX.BOSTA_FULFILLMENT_LIST]
  },
  {
    path: '/businesses/bulk/pricing/update',
    module: 'BUSINESS',
    exact: true,
    name: 'Update Business pricing tier',
    component: BulkUpdatePricingTier,
    private: true,
    access: [...aclMatrix.BUSINESSES_DETAILS]
  },
  {
    path: '/international-shipping',
    component: InternationalOrdersContainer,
    private: true,
    mainPage: true,
    access: [...aclMatrix.DELIVERIES],
    permission: [ACL_MATRIX.DELIVERIES_LIST],
    countries: [COUNTRIES_CODE_NAME.AE]
  }
  /* {
    path: '/billings',
    exact: true,
    name: 'Billings',
    component: Billings,
    private: true,
  },
  */
];

export default routes;
