import { useIntl } from 'react-intl';
import { useContext } from 'react';

import { openModal } from 'utils/modal';
import { OpsControl } from 'contexts/ops-control.context';

import useCheckOrderHub from 'components/OpsControl/WrongZone/hooks/useCheckOrderHub';
import BRButton from 'components/BRButton/BRButton';
import ChangeHubModal from './ChangeHubModal/ChangeHubModal';

const ChangeHubButton = (props) => {
  const { order, loading } = props;

  const intl = useIntl();
  const { allHubs } = useContext(OpsControl);

  const { wrongZoneActionHandler, loading: checkingOrderHub } =
    useCheckOrderHub(order);

  const viewConfirmationModal = () => {
    openModal(ChangeHubModal, {
      title: intl.formatMessage({
        id: 'ops_control.wrong_zone.modals.change_hub.title'
      }),
      allHubs,
      ...props
    });
  };

  const handleOnClick = () => wrongZoneActionHandler(viewConfirmationModal);

  return (
    <BRButton
      label={intl.formatMessage({
        id: 'ops_control.wrong_zone.table.headers.change_hub'
      })}
      onClick={handleOnClick}
      loading={checkingOrderHub}
      disabled={loading}
    />
  );
};

export default ChangeHubButton;
