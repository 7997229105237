import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Select,
  Form,
  Button,
  Input,
  Menu,
  Tooltip,
  Dropdown,
  Switch
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { polygon, featureCollection, point } from '@turf/helpers';
import { convex, centroid } from '@turf/turf';

import { getHubStars } from 'services/hubs';
import {
  getStarLogs,
  exportStarMap,
  getStarLocations
} from 'services/stars-map';
import {
  convertSecondsToTime,
  FORMAT_DATE_TO_UTC,
  FORMAT_DATE_TO_UTC_END_OF_DAY
} from 'utils/helpers';
import {
  STAR_MAP_DEFAULT_DATE_FORMAT,
  STAR_MAP_ACTIONS,
  STAR_MAP_EXCEPTIONS,
  RETURNED_TO_BUSINESS,
  STAR_MAP_DEFAULT_LOCATION,
  STAR_LOCATIONS_LIMIT
} from 'constants/stars';
import { getCenter } from 'utils/dynamic-routing';

import BRSearchableTable from 'components/BRSearchableTable/BRSearchableTable';
import { notify } from 'components/Notify/Notify';
import BRGoogleMap from 'components/BRGoogleMap/BRGoogleMap';
import StarLogsDateFilter from '../StarLogsDateFilter/StarLogsDateFilter';

import { ReactComponent as FilterIcon } from 'assets/imgRevamp/star-map-filter-icon.svg';
import { ReactComponent as DropDownIcon } from 'assets/imgRevamp/dropdown-icon.svg';
import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/InformationButtonWithoutFilter.svg';
import { ReactComponent as StarMapIcon } from 'assets/imgRevamp/star-map-icon.svg';
import { ReactComponent as InputSearchIcon } from 'assets/imgRevamp/search_icon.svg';

import './StarLogs.less';

const { Option } = Select;

const StarLogs = ({ hubs, isLoading, setIsLoading, intl }) => {
  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedStar, setSelectedStar] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [hubStars, setHubStars] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    createdAtStart: FORMAT_DATE_TO_UTC(
      dayjs().subtract(2, 'w').add(1, 'd').format(STAR_MAP_DEFAULT_DATE_FORMAT)
    ),
    createdAtEnd: FORMAT_DATE_TO_UTC_END_OF_DAY(dayjs())
  });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [searchByRadioValue, setSearchByRadioValue] =
    useState('trackingNumber');
  const [inputPlaceholder, setInputPlaceholder] = useState(
    intl.formatMessage({ id: 'star_map.search_tracking_number' })
  );
  const [count, setCount] = useState(0);
  const [callType, setCallType] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [disableApplyButton, setDisableApplyButton] = useState(true);
  const [actionFilters, setActionFilters] = useState([]);
  const [showStarPolygon, setShowStarPolygon] = useState(false);
  const [starPolygon, setStarPolygon] = useState([]);
  const [mapCenter, setMapCenter] = useState(STAR_MAP_DEFAULT_LOCATION);

  const refreshTable = useRef();
  const mapsRef = useRef(null);

  const onRadioSectionChange = (value) => {
    setSearchByRadioValue(value);
    if (value === 'trackingNumber') {
      setInputPlaceholder(
        intl.formatMessage({ id: 'star_map.search_tracking_number' })
      );
    } else {
      setInputPlaceholder(
        intl.formatMessage({ id: 'star_map.search_pickup_id' })
      );
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current({ pageId: 1 });
  };

  useEffect(() => {
    updateTable();
  }, [dateFilter]);

  useEffect(() => {
    getStarsInTheSelectedHub(selectedHub);
    setSelectedStar();
  }, [selectedHub]);

  useEffect(() => {
    resetStarPolygonSelection();
  }, [selectedStar]);

  const setDateFilterValue = ({ createdAtStart, createdAtEnd }) => {
    setDateFilter({
      createdAtStart: FORMAT_DATE_TO_UTC(createdAtStart),
      createdAtEnd: FORMAT_DATE_TO_UTC_END_OF_DAY(createdAtEnd)
    });
  };

  const getStarsInTheSelectedHub = async (value) => {
    setIsLoading(true);

    try {
      if (!value) {
        setHubStars([]);
        return;
      }
      const { message } = await getHubStars(value);
      setHubStars(message);
    } catch (error) {
      setHubStars([]);
      notify(error.message);
    }

    setIsLoading(false);
  };

  const resetStarPolygonSelection = () => {
    setShowStarPolygon(false);
    setStarPolygon([]);
  };

  const handleGetStarLocations = async (checked) => {
    if (!checked) {
      return resetStarPolygonSelection();
    }

    setIsLoading(true);
    setShowStarPolygon(true);

    const payload = {
      hubId: selectedHub,
      starId: selectedStar,
      limit: STAR_LOCATIONS_LIMIT,
      ...(searchByRadioValue === 'trackingNumber'
        ? { trackingNumber: searchValue }
        : { pickupId: searchValue }),
      ...dateFilter,
      ...(actionFilters.length && { action: actionFilters })
    };

    try {
      const {
        data: { actions = [] }
      } = await getStarLocations(payload);

      if (actions.length) {
        const points = featureCollection(
          actions.map(({ location }) => point(location))
        );

        const center = centroid(points).geometry.coordinates;

        const sortedPoints = actions
          .map(({ location }) => ({
            location,
            distance: Math.hypot(
              location[0] - center[0],
              location[1] - center[1]
            )
          }))
          .sort((a, b) => b.distance - a.distance);

        const filteredPoints = featureCollection(
          sortedPoints
            .slice(actions.length * 0.2)
            .map(({ location }) => point(location))
        );

        const adjustedStarPolygon = convex(filteredPoints);

        const polygonPath = adjustedStarPolygon.geometry.coordinates[0].map(
          (point) => ({ lat: point[0], lng: point[1] })
        );

        setStarPolygon(polygonPath);
        setMapCenter(getCenter(polygonPath));
        mapsRef?.current?.scrollIntoView();
      }
    } catch (error) {
      notify(error.message);
    }

    setIsLoading(false);
  };

  const datesAreSame = () => {
    const d1 = dayjs(dateFilter.createdAtStart);
    const d2 = dayjs(dateFilter.createdAtEnd).subtract(1, 'days');
    return d1.isSame(d2, 'day');
  };

  const renderHubsDropdown = hubs?.map((option, index) => (
    <Option key={index} value={option._id}>
      {option.name}
    </Option>
  ));

  const renderStarsDropdown = hubStars?.map((option, index) => (
    <Option key={index} value={option._id}>
      {option?.profile.firstName.replace(' ', '')} {option?.profile.lastName}
    </Option>
  ));

  const selectBefore = (
    <Select
      defaultValue="trackingNumber"
      className="select-before"
      onChange={onRadioSectionChange}
    >
      <Option value="trackingNumber">
        {intl.formatMessage({ id: 'star_map.tracking_number' })}
      </Option>
      <Option value="pickupId">
        {intl.formatMessage({ id: 'star_map.pickup_id' })}
      </Option>
    </Select>
  );

  const renderSearchSection = () => (
    <div className="br-star-log__input-search-container">
      <Input
        addonBefore={selectBefore}
        onChange={handleSearchValueChange}
        onPressEnter={updateTable}
        placeholder={inputPlaceholder}
        suffix={<InputSearchIcon />}
      />
    </div>
  );

  const handleViewOnMap = (coordinates) => {
    if (coordinates.length) {
      const location = { lng: coordinates[1], lat: coordinates[0] };
      setMarkerPosition(location);
      setMapCenter(location);
      mapsRef?.current?.scrollIntoView();
    }
  };

  const STAR_ACTION_LOGS = (action) => {
    return {
      SMS: {
        title: intl.formatMessage({ id: 'star_map.star_actions.sms_title' })
      },
      CALL: {
        title: intl.formatMessage({
          id: `star_map.star_actions.${
            !action.callDuration || action.callDuration === '0'
              ? 'call_title_no_answer'
              : 'call_title'
          }`
        }),
        subTitle:
          action.callDuration && action.callDuration !== '0' ? (
            <>
              {' '}
              {intl.formatMessage({ id: 'star_map.call_duration' })}{' '}
              {convertSecondsToTime(action?.callDuration)}
            </>
          ) : (
            ''
          )
      },
      MISSED_CALL: {
        title: intl.formatMessage({
          id: `star_map.star_actions.incoming_call_no_answer_title`
        })
      },
      INCOMING_CALL: {
        title: intl.formatMessage({
          id: `star_map.star_actions.${
            !action.callDuration || action.callDuration === '0'
              ? 'incoming_call_no_answer_title'
              : 'incoming_call_title'
          }`
        }),
        subTitle:
          action.callDuration && action.callDuration !== '0' ? (
            <>
              {intl.formatMessage({ id: 'star_map.call_duration' })}{' '}
              {convertSecondsToTime(action?.callDuration)}
            </>
          ) : (
            ''
          )
      },
      DELIVER: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.deliver_title'
        })
      },
      EXCEPTION: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.exception_title'
        }),
        subTitle: <>{action.exceptionReason}</>
      },
      COLLECT: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.collect_title'
        })
      },
      PICKED_UP_FROM_CONSIGNEE: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.picked_up_from_consignee_title'
        })
      },
      FM_PICKUP: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.fm_pickup_title'
        })
      },
      CRP_PICKUP: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.crp_pickup_title'
        })
      },
      RETURNED_TO_BUSINESS: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.returned_to_business_title'
        })
      },
      PENDING_CUSTOMER_SIGNATURE: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.pending_customer_signature_title'
        })
      },
      WHATSAPP: {
        title: intl.formatMessage({
          id: 'star_map.star_actions.whatsapp'
        })
      }
    };
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'star_map.table.tracking_no_pickup_id' }),
      dataIndex: 'trackingNumber',
      render: (trackingNumber, record) => {
        return (
          <Link
            to={
              record.redirectToTrackingPage
                ? `/deliveries/${trackingNumber}/details`
                : `/pickups/${trackingNumber}`
            }
            className="br-star-logs-delivery-link"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            {trackingNumber}
          </Link>
        );
      }
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.star' }),
      dataIndex: 'star',
      render: (star) => (
        <div className="br-star-logs__star-consignee-details">
          <span>{star?.name}</span>
          <span className="br-star-logs-call-duration">{star?.phone}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.consignee' }),
      dataIndex: 'consignee',
      render: (consignee) => (
        <div className="br-star-logs__star-consignee-details">
          <span>{consignee?.name}</span>
          <span className="br-star-logs-call-duration">{consignee?.phone}</span>
        </div>
      )
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.star_action' }),
      dataIndex: 'starAction',
      render: (action) => {
        return (
          <div className="br-star-logs__star-action-column-content">
            {STAR_ACTION_LOGS(action)[action.action].title}

            {action.action === 'EXCEPTION' ? (
              <Tooltip
                title={STAR_ACTION_LOGS(action)[action.action]?.subTitle}
              >
                <TooltipsIcon />
              </Tooltip>
            ) : (
              <span className="br-star-logs-call-duration">
                {STAR_ACTION_LOGS(action)[action.action].subTitle}
              </span>
            )}
          </div>
        );
      }
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.timestamp' }),
      dataIndex: 'timestamp',
      render: (date) => {
        return <>{dayjs(new Date(date)).tz().format('LLL')}</>;
      }
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.delivery_location' }),
      dataIndex: 'deliveryLocation',
      render: (location, record) => {
        return !!location?.length &&
          record?.starAction?.action !== RETURNED_TO_BUSINESS ? (
          <span
            className="br-star-log__location-action"
            onClick={() => handleViewOnMap(location)}
          >
            {intl.formatMessage({ id: 'star_map.table.view_on_map' })}
            <StarMapIcon />
          </span>
        ) : (
          'N/A'
        );
      }
    },
    {
      title: intl.formatMessage({ id: 'star_map.table.location' }),
      dataIndex: 'location',
      render: (location) => {
        return (
          <span
            className="br-star-log__location-action"
            onClick={() => handleViewOnMap(location)}
          >
            {intl.formatMessage({ id: 'star_map.table.view_on_map' })}
            <StarMapIcon />
          </span>
        );
      }
    }
  ];

  const handleSearchValueChange = ({ target: { value } }) => {
    if (!value) {
      return setSearchValue(null);
    }
    setSearchValue(value.split(','));
  };

  const formatStarLogsData = (data) =>
    data.map((item) => ({
      _id: item?._id,
      trackingNumber: item?.trackingNumber || item?.pickupId,
      starAction: item,
      timestamp: item?.date,
      location: item?.location?.reverse(),
      redirectToTrackingPage: !!item?.trackingNumber,
      consignee: {
        name: item.consigneeName,
        phone: item.consigneePhone
      },
      star: {
        name: item.starName,
        phone: item.starPhone
      },
      deliveryLocation: item?.deliveryLocation?.reverse()
    }));

  const getTableData = async ({ pageId, pageLimit }) => {
    const payload = {
      hubId: selectedHub,
      starId: selectedStar,
      page: pageId,
      limit: pageLimit,
      ...(searchByRadioValue === 'trackingNumber'
        ? { trackingNumber: searchValue }
        : { pickupId: searchValue }),
      ...dateFilter,
      ...(actionFilters.length && { action: actionFilters })
    };
    try {
      if ((selectedHub && selectedStar) || searchValue) {
        const { data } = await getStarLogs(payload);
        setCount(data?.starsCount || 0);
        return {
          list: formatStarLogsData(data?.actions),
          total: data?.actionsCount || 0
        };
      } else {
        setCount(0);
        return {
          list: [],
          total: 0
        };
      }
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnApplyFilterClick = () => {
    updateTable();
    setFilterMenuVisible(false);
  };

  const handleOnCallTypeChange = (value) => {
    setCallType(value);
    setActionType(null);
    setDisableApplyButton(false);
    setActionFilters(
      value === 'Incoming Call'
        ? [STAR_MAP_ACTIONS.INCOMING_CALL, STAR_MAP_ACTIONS.MISSED_CALL]
        : [STAR_MAP_ACTIONS.CALL]
    );
  };

  const handleOnActionTypeChange = (value) => {
    const valueArr = value.split(',');
    setActionType(valueArr);
    setCallType(null);
    setDisableApplyButton(false);
    setActionFilters(valueArr);
  };

  const handleOnClearFilterClick = async () => {
    await setCallType(null);
    await setActionType(null);
    await setActionFilters([]);
    setDisableApplyButton(true);
    updateTable();
  };

  const filterOptionsMenu = () => {
    return (
      <>
        <div
          className="br-analytics-filter__cities-header"
          onClick={(e) => e.stopPropagation()}
        >
          <Button onClick={handleOnClearFilterClick}>
            {intl.formatMessage({
              id: `common.clear`
            })}
          </Button>
          <span> {intl.formatMessage({ id: 'common.filters' })}</span>
          <Button
            type="primary"
            onClick={handleOnApplyFilterClick}
            disabled={disableApplyButton}
            className="br-star-logs__apply-filter-action"
          >
            {intl.formatMessage({ id: 'common.apply' })}
          </Button>
        </div>

        <Menu trigger={['click']} onClick={(e) => e.domEvent.stopPropagation()}>
          <Menu.Item onClick={(e) => e.domEvent.stopPropagation()}>
            <div>
              <div className="br-star-logs__filter-menu-content">
                <span className="br-star-logs__filter-title">
                  {intl.formatMessage({
                    id: 'star_map.call_type_title'
                  })}
                </span>
                <Select
                  onChange={handleOnCallTypeChange}
                  suffixIcon={<DropDownIcon />}
                  placeholder={intl.formatMessage({
                    id: 'star_map.call_type_placeholder'
                  })}
                  value={callType}
                >
                  <Select.Option value="Incoming Call">
                    {intl.formatMessage({
                      id: 'star_map.star_actions.incoming_call_title'
                    })}
                  </Select.Option>
                  <Select.Option value="Outgoing Call">
                    {intl.formatMessage({
                      id: 'star_map.star_actions.call_title'
                    })}
                  </Select.Option>
                </Select>
              </div>
              <div className="br-star-logs__filter-menu-content">
                <span className="br-star-logs__filter-title">
                  {intl.formatMessage({
                    id: 'star_map.action_type_title'
                  })}
                </span>
                <Select
                  onChange={handleOnActionTypeChange}
                  suffixIcon={<DropDownIcon />}
                  placeholder={intl.formatMessage({
                    id: 'star_map.call_type_placeholder'
                  })}
                  value={actionType}
                  options={STAR_MAP_EXCEPTIONS}
                />
              </div>
            </div>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  const filterComponent = () => {
    return (
      <div className="br-star-logs__filter-component-container">
        <Dropdown
          visible={filterMenuVisible}
          onVisibleChange={setFilterMenuVisible}
          overlay={filterOptionsMenu()}
          trigger={['click']}
          getPopupContainer={(trigger) => trigger.parentElement}
          overlayClassName="br-analytics-filter__cities-dropdown"
          destroyPopupOnHide
          placement="bottomRight"
        >
          <Button
            loading={isLoading}
            className="br-analytics-filter__cities-button"
            onClick={() => setFilterMenuVisible(!filterMenuVisible)}
          >
            <FilterIcon
              className={actionFilters.length && 'br-star-logs__filter-icon'}
            />
            <span className="br-analytics-filter__cities-dropdown-title">
              {intl.formatMessage({ id: 'common.filters' })}
            </span>

            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const handleOnFilterCancel = async () => {
    await setSelectedHub(null);
    await setSelectedStar(null);
    updateTable();
  };

  const handleExportTable = async () => {
    const payload = {
      hubId: selectedHub,
      starId: selectedStar,
      ...(searchByRadioValue === 'trackingNumber'
        ? { trackingNumber: searchValue }
        : { pickupId: searchValue }),
      ...dateFilter,
      ...(actionFilters.length && { action: actionFilters })
    };
    try {
      const result = await exportStarMap(payload);
      notify(result.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <div className="br-star-logs-container">
      <div className="br-star-logs__date-filter-header-content">
        <span className="br-star-logs__date-filter-title">
          {intl.formatMessage({ id: 'star_map.title' })}
        </span>
        <StarLogsDateFilter setDateFilter={setDateFilterValue} />
      </div>
      <div className="br-star-logs-hub-star-filters-container">
        <div className="br-star-logs__actions-row">
          <div className="br-star-logs-hubs-filter">
            <Form.Item
              label={intl.formatMessage({
                id: 'stars.create_edit_star.form_labels.hub'
              })}
            >
              <Select
                showSearch
                size="large"
                className="br-star-logs-hubs-filter__dropdown"
                placeholder={intl.formatMessage({
                  id: 'hubs.rto_modal.select_hub_modal.dropdown_placeholder'
                })}
                value={selectedHub}
                onSelect={setSelectedHub}
                optionFilterProp="children"
                loading={isLoading}
                disabled={isLoading}
              >
                {renderHubsDropdown}
              </Select>
            </Form.Item>
          </div>
          <div className="br-star-logs-stars-filter">
            <Form.Item
              label={intl.formatMessage({
                id: 'hubs.packages_debrief.star_label'
              })}
            >
              <Select
                showSearch
                size="large"
                className="br-star-logs-stars-filter__dropdown"
                placeholder={intl.formatMessage({ id: 'star_map.select_star' })}
                value={selectedStar}
                onSelect={setSelectedStar}
                optionFilterProp="children"
                loading={isLoading}
                disabled={isLoading}
              >
                {renderStarsDropdown}
              </Select>
            </Form.Item>
          </div>
          <Button
            className="br-star-logs__cancel-filter-action"
            onClick={handleOnFilterCancel}
          >
            {intl.formatMessage({
              id: `common.cancel`
            })}
          </Button>
          <Button
            onClick={updateTable}
            className="br-star-logs__search-filter-action"
            type="primary"
          >
            {intl.formatMessage({
              id: `common.search`
            })}
          </Button>
        </div>
        <div className="br-star-logs__star-polygon-switch">
          <Switch
            onChange={handleGetStarLocations}
            checked={showStarPolygon}
            disabled={!selectedStar}
            loading={isLoading}
          />
          <span className="body-medium">
            {intl.formatMessage({
              id: 'star_map.star_actions.show_star_area'
            })}
          </span>
        </div>
      </div>

      <div className="br-star-logs__data-container">
        <div className="br-star-logs-table">
          <BRSearchableTable
            columns={columns}
            scrollY={600}
            listFunction={getTableData}
            showFilter
            title={intl.formatMessage(
              { id: 'star_map.table.title' },
              {
                count: count
              }
            )}
            filterComponents={filterComponent}
            shareMethods={acceptMethods}
            onRowClick={() => {}}
            filterOptions={renderSearchSection}
            scroll={300}
            exportListFileFunction={handleExportTable}
            disableExport={!datesAreSame()}
          />
        </div>
        <div className="br-star-logs-map-container" ref={mapsRef}>
          <BRGoogleMap
            markerPosition={markerPosition}
            polygon={starPolygon}
            center={mapCenter}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(StarLogs);
