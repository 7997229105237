import { useCallback, useState } from 'react';

import { assignWrongZoneOrderHub } from 'services/ops-control';

import { notify } from 'components/Notify/Notify';
import ChangeHubButton from './ChangeHubButton/ChangeHubButton';
import NoChangeButton from './NoChangeButton/NoChangeButton';

import './WrongZoneActions.less';

const WrongZoneActions = ({
  order = {},
  refreshData = () => {},
  showNoChangeAction = true
}) => {
  const [loading, setLoading] = useState(false);

  const dispatchOrderAction = useCallback(
    async ({ targetHubId, successMessage = '' } = {}) => {
      setLoading(true);
      try {
        await assignWrongZoneOrderHub(order.id, targetHubId);
        refreshData();
        notify(successMessage, 'success');
      } catch (e) {
        notify(e.message);
      } finally {
        setLoading(false);
      }
    },
    [order.id, refreshData]
  );

  return (
    <div className="br-ops-control-wrong-zone__actions">
      <ChangeHubButton
        dispatchOrderAction={dispatchOrderAction}
        loading={loading}
        order={order}
      />

      {showNoChangeAction && (
        <NoChangeButton
          dispatchOrderAction={dispatchOrderAction}
          loading={loading}
          order={order}
        />
      )}
    </div>
  );
};

export default WrongZoneActions;
