import { Select } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import WrongZoneConfirmationModal from 'components/OpsControl/WrongZone/components/WrongZoneConfirmationModal/WrongZoneConfirmationModal';

import { ReactComponent as ChevronDownIcon } from 'assets/bosta-icons/Chevron-down.svg';
import { ReactComponent as EditIcon } from 'assets/imgRevamp/ccod-edit-cell.svg';

const ChangeHubModal = ({
  order,
  dispatchOrderAction,
  loading,
  allHubs = [],
  ...props
}) => {
  const intl = useIntl();

  const hubsOptions = allHubs
    .filter((hub) => hub._id !== order.current_assigned_hub_id)
    .map((h) => ({
      label: h.name,
      value: h._id
    }));

  const firstHub = hubsOptions?.[0]?.value;
  const [newHub, setNewHub] = useState(firstHub);
  const newHubName = hubsOptions?.find((hub) => hub.value === newHub)?.label;

  const handleChangeNewHub = (selectedHub) => {
    setNewHub(selectedHub);
  };

  const onSuccess = () =>
    dispatchOrderAction({
      targetHubId: newHub,
      successMessage: intl.formatMessage(
        {
          id: 'ops_control.wrong_zone.modals.change_hub.success_message'
        },
        {
          newHubName
        }
      )
    });

  return (
    <WrongZoneConfirmationModal
      {...props}
      onSuccess={onSuccess}
      disableConfirmButton={!newHubName}
      icon={<EditIcon />}
    >
      <div className="br-ops-control-wrong-zone__destination-hub-select">
        <span>
          {intl.formatMessage({
            id: 'ops_control.wrong_zone.modals.change_hub.hub'
          })}
        </span>
        <Select
          key={newHub}
          loading={loading}
          disabled={loading || !allHubs?.length}
          options={hubsOptions}
          value={newHub}
          onChange={handleChangeNewHub}
          suffixIcon={<ChevronDownIcon />}
          dropdownMatchSelectWidth
        />
      </div>
    </WrongZoneConfirmationModal>
  );
};

export default ChangeHubModal;
