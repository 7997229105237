import { Modal, Form } from 'antd';

import { formatImages } from 'utils/upload';
import { getSignedUrl } from 'services/deliveries';
import { imageUpload } from 'services/upload';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';

import './UploadRejectModal.less';

const UploadRejectModal = ({ close, confirm, intl, ...props }) => {
  const onFinish = async (values) => {
    const imagesUrls = await getImagesUrl(values?.contractImage);
    confirm(imagesUrls);
    close();
  };

  const getImagesUrl = async (values) => {
    let imagesUrls = [];
    try {
      const formattedImages = formatImages(values);
      for (let i = 0; i < formattedImages?.length; i++) {
        const payload = {
          type: 'rejectedReturnImages'
        };
        const { data } = await getSignedUrl(payload);
        await imageUpload(data?.signedUrl, formattedImages[i]);
        imagesUrls.push(data?.signedUrl);
      }
    } catch (error) {
      notify(error.message);
    }
    return imagesUrls;
  };
  return (
    <Modal {...props} footer={null} onCancel={close}>
      <h2 className="caption-lg">
        {intl.formatMessage({
          id: 'hubs.packages_debrief.exceptions_tab.reject_modal.header'
        })}
      </h2>
      <div className="br-international-shipping-modal__upload-container">
        <Form onFinish={onFinish} name="uploadContactForm">
          <Form.Item
            label="upload images"
            name="contractImage"
            rules={[{ required: true }]}
          >
            <UploadAttachment
              maxCount={3}
              maxSize={15}
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
              disableEncoding
              noMaxTotalSize
              hideUploadButtonAfterMaxCount
            />
          </Form.Item>
        </Form>
      </div>
      <div className="br-international-shipping-modal__footer">
        <BRButton
          block
          label={intl?.formatMessage({ id: 'common.cancel' })}
          onClick={close}
        />
        <BRButton
          block
          type="primary"
          htmlType="submit"
          form="uploadContactForm"
          label="confirm"
        />
      </div>
    </Modal>
  );
};
export default UploadRejectModal;
