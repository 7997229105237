import { useIntl } from 'react-intl';
import { useState } from 'react';

import { getOrderDetails } from 'services/shipments';
import { openModal } from 'utils/modal';

import { notify } from 'components/Notify/Notify';
import WrongZoneConfirmationModal from '../components/WrongZoneConfirmationModal/WrongZoneConfirmationModal';

import { ReactComponent as InfoIcon } from 'assets/bosta-icons/info.svg';

const useCheckOrderHub = ({
  action_hub_id: actionHubId,
  current_assigned_hub_id: currentHubId,
  current_assigned_hub_name: currentHubName,
  tracking_number
} = {}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const wrongZoneActionHandler = async (callback = () => {}) => {
    setLoading(true);
    try {
      const orderDetails = await getOrderDetails(tracking_number);
      const { _id, name: updatedHubName } = orderDetails?.[0]?.assignedHub;
      const assignedHubHasChanged = ![actionHubId, currentHubId].includes(_id);

      if (!assignedHubHasChanged) return callback();

      return openModal(WrongZoneConfirmationModal, {
        title: intl.formatMessage({
          id: 'ops_control.wrong_zone.modals.hub_check.title'
        }),
        icon: <InfoIcon />,
        children: intl.formatMessage(
          {
            id: 'ops_control.wrong_zone.modals.hub_check.message'
          },
          {
            currentHubName,
            updatedHubName
          }
        ),
        onSuccess: callback
      });
    } catch (e) {
      notify(e.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    wrongZoneActionHandler,
    loading
  };
};

export default useCheckOrderHub;
