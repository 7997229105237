import dayjs from 'dayjs';

import { SLLR_ACTIVATION_QUICK_FILTERS } from 'constants/sllr-analytics';
import {
  BUSINESS_DATES_FORMAT,
  BUSINESS_FEES_APPLICATION_MAP
} from 'constants/business-details';
import {
  BUSINESS_LEGAL_DOCUMENTS_FLAGS,
  LOG_TYPE_MAPPING,
  OTP,
  PAYMENT_FREQUENCY_API,
  REG_SOURCE,
  SALES_CHANNEL_OPTIONS
} from 'constants/Businesses';
import { PAYMENT_FREQUENCY_SCHEDULE } from 'constants/pricing';
import { PROMOTION_SUBTYPES_MAPPING } from 'constants/promotions';
import { getBasicllrInfo, getBusiness } from 'services/business';
import { getFulfillmentTierList } from 'services/fulfillment-pricing';
import { getActivePromotions } from 'services/promotions';
import { capitalizeFirstLetter } from './helpers';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const fetchBusinessDetailsData = async (businessId, cancelToken) => {
  const businessDataPromise = getBusiness(businessId, { cancelToken });
  const sllrPromise = getBasicllrInfo(businessId, { cancelToken });
  const promotionsPromise = getActivePromotions({
    businessId,
    pageNumber: 0,
    pageSize: 1
  });

  const [
    businessData,
    { data: basicSllrData },
    {
      data: { promotions: promotionsData }
    }
  ] = await Promise.all([businessDataPromise, sllrPromise, promotionsPromise]);

  let fulfillmentData = [];

  if (businessData.fulfillmentPricingTierId) {
    const {
      data: { fulfillmentTiers }
    } = await getFulfillmentTierList({
      cancelToken
    });

    fulfillmentData = fulfillmentTiers;
  }

  return {
    businessData: { ...businessData, basicSllrData },
    fulfillmentData,
    promotionsData
  };
};

const getBusinessCashoutFrequency = ({ paymentFrequency, paymentSchedule }) => {
  switch (paymentFrequency) {
    case PAYMENT_FREQUENCY_API.DAILY:
      return fmt({
        id: 'businesses.details.walletInfo.cashout_frequency.daily'
      });
    case PAYMENT_FREQUENCY_API.NEXT_DAY:
      return fmt({
        id: 'businesses.details.walletInfo.cashout_frequency.next_day'
      });
    case PAYMENT_FREQUENCY_API.WEEKLY:
      return fmt(
        {
          id: 'businesses.details.walletInfo.cashout_frequency.weekly'
        },
        {
          day: paymentSchedule[0]?.slice(0, 3)
        }
      );
    default:
      if (paymentSchedule.length > 1) {
        return fmt(
          {
            id: 'businesses.details.walletInfo.cashout_frequency.x_days'
          },
          {
            daysCount: paymentSchedule.length,
            days: paymentSchedule
              .map((day) => day.toString().slice(0, 3))
              .join(', ')
          }
        );
      }
      return '';
  }
};

const getBusinessInfo = (data) => {
  const {
    _id,
    createdAt,
    industry,
    type,
    businessLegalInfo = {},
    regSrc,
    internationalBusinessNetsuiteId,
    salesChannel,
    arabicName,
    desc,
    website,
    deliveryLocationPresets = []
  } = data;

  const accountTypeIds = [
    `business_details.business_info.${
      businessLegalInfo.legalBusinessName ? 'company' : 'individual'
    }`,
    `sllr_analytics.sllr_activation_filters.${
      regSrc === SLLR_ACTIVATION_QUICK_FILTERS[2].value ? 'sllr' : 'bosta'
    }`
  ];
  const accountType = accountTypeIds.map((id) => fmt({ id })).join(' ');

  const canDownloadLegalDocuments = Object.keys(businessLegalInfo || {}).some(
    (key) => key && BUSINESS_LEGAL_DOCUMENTS_FLAGS.includes(key)
  );

  const {
    isInternationalBankCertificateIdUploaded,
    isInternationalSecondBankCertificateIdUploaded
  } = businessLegalInfo;

  const canDownloadPaymentDocuments =
    isInternationalSecondBankCertificateIdUploaded ||
    isInternationalBankCertificateIdUploaded;

  return {
    businessId: _id,
    canDownloadLegalDocuments,
    canDownloadPaymentDocuments,
    joiningDate: createdAt && dayjs(createdAt).format('DD MMM YYYY hh:mm A'),
    industry,
    type,
    accountType,
    netSuitId: internationalBusinessNetsuiteId,
    signupSource: REG_SOURCE[regSrc] || regSrc,
    salesChannel: salesChannel?.map(
      (channel) =>
        SALES_CHANNEL_OPTIONS.find((option) => option.value === channel)
          ?.label ?? channel
    ),
    arabicName,
    website,
    description: desc,
    numberOfPickupLocations: deliveryLocationPresets.length + 1
  };
};

const getPricingInfo = (data) => {
  const {
    pricingFlags: {
      isExtraCodFeesApplied = false,
      isZeroCodDiscountApplied = false,
      isInsuranceFeesApplied = false,
      isExpediteFeesApplied = false,
      isCodFeesApplied = false,
      isPosFeesApplied = false
    } = {}
  } = data;

  return {
    extraFees: BUSINESS_FEES_APPLICATION_MAP[isExtraCodFeesApplied],
    zeroDiscount: BUSINESS_FEES_APPLICATION_MAP[isZeroCodDiscountApplied],
    insuranceFees: BUSINESS_FEES_APPLICATION_MAP[isInsuranceFeesApplied],
    nextDayFees: BUSINESS_FEES_APPLICATION_MAP[isExpediteFeesApplied],
    codFees: BUSINESS_FEES_APPLICATION_MAP[isCodFeesApplied],
    ccodFees: BUSINESS_FEES_APPLICATION_MAP[isPosFeesApplied]
  };
};

const getWalletInfo = (data) => {
  const {
    businessType,
    businessCategory,
    paymentInfo: {
      paymentTransferMethod = [],
      paymentType,
      creditLimit,
      paymentFrequency,
      paymentSchedule = []
    } = []
  } = data;

  const formattedCreditLimit = creditLimit
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return {
    businessType,
    businessCategory,
    paymentGateway: paymentTransferMethod.join(', '),
    paymentType,
    creditLimit: formattedCreditLimit,
    cashoutFrequency: getBusinessCashoutFrequency({
      paymentFrequency,
      paymentSchedule
    })
  };
};

const getFlyersInfo = (data) => {
  const {
    _id,
    name,
    flyersInfo: { availableNoOfFlyers = 0, maxLimitOfFlyers = 0 } = {}
  } = data;

  return {
    businessId: _id,
    businessName: name,
    availableNoOfFlyers,
    currentMerchantQuota: fmt(
      { id: 'businesses.details.flyersInfo.quota_value' },
      { quota: availableNoOfFlyers }
    ),
    initialQuota: fmt(
      { id: 'businesses.details.flyersInfo.quota_value' },
      { quota: maxLimitOfFlyers }
    )
  };
};

const getBankInfo = (data, fieldName = 'bankInfo') => {
  const {
    [fieldName]: {
      bankName,
      beneficiaryName: accountOwnerName,
      accountNumber,
      ibanNumber
    } = {}
  } = data;

  return {
    bankName,
    accountOwnerName,
    accountNumber,
    ibanNumber
  };
};

const getInternationalWalletInfo = (data) => {
  const {
    internationalPricingTier: { name: internationalPricingPlan } = {},
    internationalPaymentInfo: { paymentSchedule = [] } = {}
  } = data;

  return {
    internationalPricingPlan,
    cashoutFrequency: PAYMENT_FREQUENCY_SCHEDULE.find(
      (item) => item.paymentSchedule.toString() === paymentSchedule?.toString()
    )?.label
  };
};

const getOperationsSettingsInfo = (data) => {
  const { isRtoOtp, pickupProofType, numberOfAttempts } = data;
  const proofOfReturn =
    isRtoOtp === true
      ? fmt({
          id: 'business_details.operations_settings.otp'
        })
      : fmt({
          id: 'business_details.operations_settings.signature'
        });

  const pickupProof =
    pickupProofType === OTP
      ? fmt({
          id: 'business_details.operations_settings.otp'
        })
      : fmt({
          id: 'business_details.operations_settings.signature'
        });

  return {
    numberOfAttempts,
    proofOfReturn,
    pickupProof
  };
};

const getFulfillmentInfo = (data) => {
  return (fulfillmentData = []) => {
    const { fulfillmentPricingTierId } = data;
    const pricingPlan = fulfillmentData?.find(
      (tier) => tier?._id === fulfillmentPricingTierId
    )?.name;

    return {
      pricingPlan
    };
  };
};

export const getPromotionsInfo = (data) => {
  const {
    percentage,
    activeAt,
    inactiveAt,
    subType,
    ownerInfo: { email } = {},
    createdAt: creationDate
  } = data?.[0] ?? {};

  const value = `${(percentage * 100).toFixed(2)}%`;
  const effectiveFrom =
    activeAt && dayjs(activeAt).format(BUSINESS_DATES_FORMAT);
  const expiryDate =
    inactiveAt && dayjs(inactiveAt).format(BUSINESS_DATES_FORMAT);
  const createdAt =
    creationDate && dayjs(creationDate).format(BUSINESS_DATES_FORMAT);

  return {
    value,
    effectiveFrom,
    expiryDate,
    subType: PROMOTION_SUBTYPES_MAPPING[subType],
    takenBy: email,
    createdAt
  };
};

const getDefaultBusinessLocationInfo = (data) => {
  const {
    _id: businessId,
    defaultPickupLocation: {
      locationName,
      address: {
        city,
        zone,
        district,
        buildingNumber: building,
        apartment,
        floor,
        firstLine: street,
        secondLine: landmark
      } = {},
      contacts = [],
      contactPerson = {},
      returnContactPerson = {}
    } = {}
  } = data;

  const location = {
    city: city?.name,
    area: `${zone?.name ? zone.name : ''}${
      district?.name ? ` - ${district?.name}` : ''
    }`,
    building,
    apartment,
    floor,
    street,
    landmark
  };

  const businessContacts = contacts.map((contact) => ({
    ...contact,
    pickupDefaultContact: contact._id === contactPerson._id,
    returnDefaultContact: contact._id === returnContactPerson._id
  }));

  const defaultContact = businessContacts.find(
    ({ pickupDefaultContact, returnDefaultContact }) =>
      pickupDefaultContact && returnDefaultContact
  );

  return {
    businessId,
    locationName,
    location,
    businessContacts: businessContacts.filter(
      ({ _id }) => _id !== defaultContact?._id
    ),
    defaultContact
  };
};

const getLogsTimelineInfo = (data) => {
  const { logs = [] } = data;

  const formattedLogs = logs
    .map(({ time, value, type, takenBy: { email } = {} }) => ({
      _id: `${time}+${value}+${type}`,
      type: LOG_TYPE_MAPPING[type] || fmt({ id: 'common.empty_field' }),
      value,
      date: time
        ? dayjs(time).format(BUSINESS_DATES_FORMAT)
        : fmt({ id: 'common.empty_field' }),
      takenBy: email || fmt({ id: 'common.empty_field' })
    }))
    .toReversed();

  return { formattedLogs };
};

const getSllrInfo = (data) => {
  const {
    _id: businessId,
    basicSllrData: { storeLink, storeName, storeState, businessStatus } = {}
  } = data;
  return {
    businessId,
    storeLink,
    storeName,
    sllrStore: capitalizeFirstLetter(storeState),
    businessStatus
  };
};

export const formatBusinessDetails = (data = {}) => {
  return {
    businessInfo: getBusinessInfo(data),
    pricingInfo: getPricingInfo(data),
    walletInfo: getWalletInfo(data),
    flyersInfo: getFlyersInfo(data),
    bankInfo: getBankInfo(data),
    secondBankInfo: getBankInfo(data, 'secondBankInfo'),
    internationalWalletInfo: getInternationalWalletInfo(data),
    operationsSettingsInfo: getOperationsSettingsInfo(data),
    fulfillmentInfo: getFulfillmentInfo(data),
    defaultBusinessLocationInfo: getDefaultBusinessLocationInfo(data),
    logsTimelineInfo: getLogsTimelineInfo(data),
    sllrInfo: getSllrInfo(data)
  };
};
